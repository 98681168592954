import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PopupModal from "../../containers/PopupModal";
import { isLogin } from "../../lib/constant";
import { toast } from "react-toastify";
import { logoutApi } from "../../services/AuthServices";

function Drawer({ navigationData, action, drawer, drawerPosition }) {
  const [itemSize, setSize] = useState("0px");
  const [item, setItem] = useState();
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();

  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (value !== item) {
      setSize(`${47 * getItems}px`);
      setItem(value);
    } else {
      setItem("");
      setSize("0px");
    }
  };

  const logOutClicked = async (e) => {
    // e.preventDefault();
    // localStorage.removeItem("is_authenticated");
    // localStorage.removeItem("token");
    // localStorage.removeItem("userId");

    // isLogin();
    // navigate("/");
    // toast("Logout Successfully", { type: "success" });
    // action(e);

    await logoutApi()
      .then(() => {
        e.preventDefault();
        localStorage.removeItem("is_authenticated");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");

        isLogin();
        navigate("/");
        toast("Logout Successfully", { type: "success" });
        action(e);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  let loggedIn = [
    {
      id: 11,
      title: "Login",
      link: "/login",
    },
    {
      id: 12,
      title: "Register",
      link: "/register",
    },
  ];
  let loggedOut = [
    {
      id: 11,
      title: "Account details",
      link: "/account-detail",
    },
  ];

  let data = [
    ...navigationData,
    {
      id: 2,
      title: "Account",
      link: "/account-detail",
      submenu: true,
      items: !isLogin() ? loggedIn : loggedOut,
    },
  ];


  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`binduz-er-news-off_canvars_overlay  ${
          drawer ? "binduz-er-news-active" : ""
        }`}
      ></div>
      <div className={`binduz-er-news-offcanvas_menu ${drawerPosition || "" }`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`binduz-er-news-offcanvas_menu_wrapper overflow-style-none ${
                  drawer ? "binduz-er-news-active" : ""
                }`}
              >
                <div
                  onClick={(e) => action(e)}
                  className="binduz-er-news-canvas_close"
                >
                  <a>
                    <i className="fal fa-times" style={{ color: "white" }}></i>
                  </a>
                </div>
                <div className="binduz-er-news-header-social">
                  <ul className="text-center">
                    <li>
                      <a>facebook</a>
                    </li>
                    <li>
                      <a>Twitter</a>
                    </li>
                    <li>
                      <a>Skype</a>
                    </li>
                  </ul>
                </div>
                <div id="menu" className="text-left ">
                  <ul className="binduz-er-news-offcanvas_main_menu">
                    {data &&
                      data?.length > 0 &&
                      data?.map((menuItem) => (
                        <li
                          key={Math.random() + menuItem.id}
                          onClick={(e) => handler(e, menuItem.title)}
                          className="binduz-er-news-menu-item-has-children binduz-er-news-active"
                        >
                          <Link to={!menuItem.submenu && menuItem?.link}>
                            {menuItem.title}
                          </Link>
                          <ul
                            id={menuItem.title}
                            className="binduz-er-news-sub-menu overflow-hidden"
                            style={{
                              height:
                                item === menuItem.title ? itemSize : "0px",
                            }}
                          >
                            {menuItem.submenu &&
                              menuItem.items.map((submenu) => (
                                <li key={Math.random() + submenu.id}>
                                  <Link to={submenu.link}>{submenu.title}</Link>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}
                  </ul>
                </div>
                {isLogin() && (
                  <div style={{ textAlign: "center" }} onClick={logOutClicked}>
                    Log Out
                  </div>
                )}
                <div className="binduz-er-news-offcanvas_footer">
                  <div className="binduz-er-news-logo text-center mb-30 mt-30">
                    <Link to="/">
                      <img
                        src={require(`../../assets/images/az-logo.png`).default}
                        alt=""
                      />
                    </Link>
                  </div>
                  <p>
                    © AZIndia.com 1999-2024 AZIndia.com is not affiliated to any
                    businesses that are listed or advertised on this site and
                    will not be liable for any products/services aquired from
                    them.
                  </p>

                  {/* <ul>
                    <li style={{color:'white'}}>
                      <Link to="/about">Our Story</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy & Policy</Link>
                    </li>
                    
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drawer;
