import React, { useCallback, useEffect, useState } from "react";
import CardRowModule from "../../containers/PostCards/CardRowModule";
import Pagination from "../../containers/Pagination";
import SideADModule from "../../containers/PostCards/SideADModule";
import { getAZTimesData } from "../../services/HomeServices";
import {
  formatDate,
  formatDateToMMYYYY,
  plainString,
} from "../../lib/constant";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import _ from "lodash";
import Loader from "../../containers/Loader";
import { useNavigate } from "react-router-dom";

const MainSection = () => {
  const [azTimesData, setazTimesData] = useState([]);
  const [selectedMonth, setselectedMonth] = useState(new Date());
  const [search, setsearch] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [links, setlinks] = useState([]);
  const [firstTime, setfirstTime] = useState(true);

  useEffect(() => {
    getAZData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [page]);

  useEffect(() => {
    if (!firstTime) {
      setpage(1);
      setTimeout(() => {
        getAZData();
      }, 500);
    }
  }, [search, selectedMonth]);

  const navigate = useNavigate();

  const getAZData = async () => {
    setisLoading(true);
    await getAZTimesData(search, formatDateToMMYYYY(selectedMonth), page)
      .then((response) => {
        setazTimesData(response?.data?.data?.data);
        setfirstTime(false);
        setisLoading(false);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div
            className=" col-lg-9"
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">
                <i
                  className="far fa-newspaper"
                  style={{ color: "red", marginRight: "5px" }}
                ></i>
                AZ TIMES
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              className="col-lg-12"
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="date-picker">
                    <ReactDatePicker
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                      autoComplete="off"
                      placeholderText="Select Month & Year"
                      selected={selectedMonth}
                      onChange={(e) => {
                        setselectedMonth(e);
                      }}
                      name="date"
                    />
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="binduz-er-author-sidebar-search-bar col-lg-4">
                    <form
                      onSubmit={(e) => {
                        e?.preventDefault();
                        getAZData();
                      }}
                    >
                      <div
                        className="binduz-er-input-box"
                        style={{ width: "270px" }}
                      >
                        <input
                          type="text"
                          onChange={(e) => setsearch(e?.target?.value)}
                          value={search}
                          placeholder="Search from here..."
                          style={{ lineHeight: "38px" }}
                        />
                        <button type="submit" style={{ height: "40px" }}>
                          <i className="fal fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {!isLoading ? (
              <>
                {!_.isEmpty(azTimesData) ? (
                  <>
                    {azTimesData?.map((item, index) => (
                      <CardRowModule
                        key={item.id}
                        datas={{
                          data: { ...item, isAzTimes: true },
                          image: item?.image,
                          category: item?.title,
                          index: index,
                          isTopStories: true,
                          imageSize: "130px",
                          title: plainString(item?.content),
                          pathname: `/details/${item?.id}`,
                          onClickFun: () => {
                            navigate(`/details/${item?.id}`, {
                              state: { ...item, isAzTimes: true },
                            });
                          },
                          date: formatDate(item?.date),
                        }}
                      />
                    ))}
                    <Pagination links={links} setpage={setpage} />
                  </>
                ) : (
                  <h5 style={{ textAlign: "center" }}>No Data Found</h5>
                )}
              </>
            ) : (
              <div style={{ marginTop: "80px" }}>
                <Loader />
              </div>
            )}
          </div>
          <div
            className=" col-lg-3"
            // style={{ paddingRight: "85px", paddingLeft: "55px" }}
          >
            {/* <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">EXPLORE YOUR COUNTRY</h3>
            </div>
            <div className="binduz-er-footer-gallery ">
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map(() => (
                  <div className="">
                    <a href="#">
                      <img
                        height={85}
                        src={
                          require(`../../assets/images/classifield.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map(() => (
                  <div className="">
                    <a href="#">
                      <img
                        height={85}
                        src={
                          require(`../../assets/images/classifield.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map(() => (
                  <div className="">
                    <a href="#">
                      <img
                        height={85}
                        src={
                          require(`../../assets/images/classifield.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>

            <div
              className="binduz-er-footer-gallery "
              style={{ marginTop: "77px" }}
            >
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map(() => (
                  <div className="binduz-er-item">
                    <a href="#">
                      <img
                        height={155}
                        width={165}
                        src={
                          require(`../../assets/images/classifield.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map(() => (
                  <div className="binduz-er-item">
                    <a href="#">
                      <img
                        height={155}
                        width={165}
                        src={
                          require(`../../assets/images/classifield.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div> */}
            <SideADModule isClassifield={true} isEvent={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
