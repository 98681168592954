import React, { useEffect, useState } from "react";
import drImg from "../../assets/images/default_doctor_img.png";
import { getPremiumDoctorData } from "../../services/HomeServices";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../containers/Loader";

const DoctorDesign = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [lastPage, setlastPage] = useState(0);
  const [toggle, settoggle] = useState(0);
  const [page, setpage] = useState(1);

  const navigate = useNavigate();

  // useEffect(() => {
  //      setTimeout(() => {
  //        getDocData( page,true);
  //      }, 700);
  // }, []);
  useEffect(() => {
    getDocData(page, true);
  }, []);

  // useEffect(() => {
  //   if (toggle > 0 && page !== lastPage && page < lastPage + 1) {
  //     getDocData(page + 1);
  //     setpage(page + 1);
  //   } else if (lastPage !== 1) {
  //     getDocData(1);
  //     setpage(1);
  //   }
  // }, [toggle]);

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setTimeout(() => {
  //         settoggle(toggle+1)
  //       }, 800);
  //     }, 10000);

  //     return () => clearInterval(interval);
  //   }, [page]);

  const getDocData = async (pageNumber, firstTimeCalled) => {
    firstTimeCalled && setisLoading(true);

    await getPremiumDoctorData(pageNumber, 12)
      .then((response) => {
        let resData = response?.data?.data?.data;
        setdata(resData);
        setlastPage(response?.data?.data?.last_page);
        firstTimeCalled && setisLoading(false);
      })
      .catch((error) => {
        firstTimeCalled && setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };
  return (
    <div className="container-fluid bg_white">
      <div className="container doctors_main">
        <div className="row">
          <div className="col-6">
            <h2>Doctors</h2>
          </div>
          <div className="col-6">
            {" "}
            <Link to="/doctor-post" className="btn_post">
              Post <i className="fas fa-plus" />
            </Link>{" "}
          </div>
        </div>
        {!isLoading ? (
          <div className="row pt-4">
            {/* <div className="col-sm-4">
              <div
                className="doctor_photo"
                onClick={() =>
                  navigate(`/doctor/${data[0]?.id}`, { state: data[0] })
                }
              >
                <h5>
                  {data[0]?.name} <span>{data[0]?.category?.name}</span>
                </h5>
                <img
                  src={data[0]?.profile_image || img}
                  alt="photo"
                  style={{ height: "375px", width: "375px" }}
                />
              </div>
            </div> */}
            <div className="col-sm-12 ods_pd0">
              <div className="row">
                {data?.slice(0, 11)?.map((x) => (
                  <div
                    className="col-sm-2 col-6"
                    onClick={(e) => {
                      e?.preventDefault();
                      navigate(`/doctor/${x?.id}`, { state: x });
                    }}
                  >
                    <a href="#" className="doctor_photo">
                      <h5>
                        {x?.name} <span>{x?.category?.name}</span>
                      </h5>
                      <img
                        style={{ height: "", width: "" }}
                        src={x?.profile_image || drImg}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Link to="/doctor" className="btn_seeall">
        See All <i className="fas fa-chevron-right" />
      </Link>{" "}
    </div>
  );
};

export default DoctorDesign;
